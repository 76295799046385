<template>
  <v-dialog
    v-model="isVisible"
    persistent
    max-width="600px"
  >
    <v-card v-if="!isLoading">
      <v-card-title>
        <span class="headline">{{ isEditMode? "Edit ": "Add New " }} Sender</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="field.name"
                label="Sender Name *"
                required
                :error="!(field.name && field.name.length) && isFormDirty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="selectedSender && selectedSender.senderType && selectedSender.senderType.length"
              cols="12"
            >
              <v-text-field
                v-if="selectedSender.senderType !== 'MessagingService'"
                v-model="field.number"
                v-mask="'+1 (###) ###-####'"
                placeholder="+1 (___) ___-____"
                required
                disabled="true"
                label="Sender Number *"
                :error="!(field.number && field.number.length) && isFormDirty"
              />
              <v-text-field
                v-if="selectedSender.senderType === 'MessagingService'"
                v-model="field.number"
                placeholder=""
                required
                disabled="true"
                label="Messaging Service Id *"
                :error="!(field.number && field.number.length) && isFormDirty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="field.signature"
                label="Signature"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="field.inviteCode"
                label="Invite Code"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="field.welcomeMessage"
                label="Welcome Message"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-select
                v-model="field.selectedResponseAction"
                :items="senderResponseActions"
                label="Select a response action *"
                item-text="SMSReponseActionType"
                item-value="SMSResponseActionTypeID"
                required
                :error="!(field.selectedResponseAction) && isFormDirty"
              />
            </v-col>
          </v-row>
          <v-row v-if="field.selectedResponseAction && field.selectedResponseAction === 1">
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="field.forwardNumber"
                v-mask="'+1 (###) ###-####'"
                placeholder="+1 (___) ___-____"
                required
                label="Number to forward *"
                :error="!(field.forwardNumber && field.forwardNumber.length) && isFormDirty"
              />
            </v-col>
          </v-row>
          <v-row v-if="field.selectedResponseAction && field.selectedResponseAction === 2">
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="field.responseMessage"
                label="Response Message"
                :error="!(field.responseMessage && field.responseMessage.length) && isFormDirty"
              />
            </v-col>
          </v-row>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="submit"
        >
          {{ isEditMode? "Update": "Add" }}
        </v-btn>
      </v-card-actions>
      <v-snackbar
        v-model="snackbar"
      >
        {{ notification }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
    <v-card v-if="isLoading">
      <div class="loaderWrapper">
        <v-progress-circular
          :size="50"
          :width="3"
          color="amber"
          indeterminate
          class="spinner"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import { VueMaskDirective } from 'v-mask'
  import Vue from 'vue'
  Vue.directive('mask', VueMaskDirective)

  export default {
    name: 'SenderAddEditDialog',
    props: {
      isVisible: {
        type: Boolean,
        default: false,
      },
      isEditMode: {
        type: Boolean,
        default: false,
      },
      selectedSender: {
        type: Object,
        default: null,
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      username: null,
      snackbar: false,
      field: {
        name: '',
        number: '',
        signature: '',
        inviteCode: '',
        welcomeMessage: '',
        selectedResponseAction: '',
        forwardNumber: '',
        responseMessage: '',
      },
      isLoading: false,
      isFormDirty: false,
      senderResponseActions: [],
      SMSSenderReponseActionTypeID: null,
    }),

    watch: {
      async isVisible (val) {
        if (val) {
          this.isFormDirty = false
          // Dialog opened
          this.isLoading = true
          if (!(this.senderResponseActions && this.senderResponseActions.length)) {
            await this._fetchAvailableResponseActions()
          }
          if (this.isEditMode) {
            await this._fetchSenderResponseAction()
            this.isLoading = false
            // Opening in edit mode
            // Assign exisiting entry values
            if (this.selectedSender.sendNumber && this.selectedSender.sendNumber.length) {
              this.field.number = this.prepareMaskedNumber(this.selectedSender.sendNumber)
            }
            if (this.selectedSender.senderName && this.selectedSender.senderName.length) {
              this.field.name = this.selectedSender.senderName
            }
            if (this.selectedSender.signiture && this.selectedSender.signiture.length) {
              this.field.signature = this.selectedSender.signiture
            }
            if (this.selectedSender.inviteCode && this.selectedSender.inviteCode.length) {
              this.field.inviteCode = this.selectedSender.inviteCode
            }
            if (this.selectedSender.welcomeMessage && this.selectedSender.welcomeMessage.length) {
              this.field.WelcomeMessage = this.selectedSender.welcomeMessage
            }
          } else {
            // Opening in add mode
            // Reset all fields
            this.field = Object.assign({
              name: '',
              number: '',
              signature: '',
              inviteCode: '',
              welcomeMessage: '',
              selectedResponseAction: '',
              forwardNumber: '',
              responseMessage: '',
            })
          }
        } else {
        }
      },
    },

    created () {
      this.username = localStorage.getItem('username')
    },

    methods: {
      async initialize () {
      },

      prepareMaskedNumber (n) {
        let maskedNumber = ''
        maskedNumber = n.replace('+1', '').replace(/[^0-9,]+/g, '')
        maskedNumber = `+1 (${maskedNumber.slice(0, 3)}) ${maskedNumber.slice(3, 6)}-${maskedNumber.slice(6, 10)}`
        return maskedNumber
      },

      parseCellNumberFromMaskFormat (cellNumber) {
        return `+${cellNumber.replace(/[^0-9,]+/g, '')}`
      },

      close () {
        this.$emit('on-close')
      },

      isFormValid () {
        this.notification = ''
        if (!this.field.name || !this.field.name.length) {
          this.notification = 'Fill all the required fields'
          return false
        }
        if (!this.field.number || !this.field.number.length) {
          this.notification = 'Provide a valid 10 digit cell number'
          return false
        }
        if (!this.field.selectedResponseAction) {
          this.notification = 'Please select a response action'
          return false
        }
        if (this.field.selectedResponseAction === 1 && (!this.field.forwardNumber || !this.field.forwardNumber.length)) {
          this.notification = 'Please provide a valid number to forward response'
          return false
        }
        if (this.field.selectedResponseAction === 2 && (!this.field.responseMessage || !this.field.responseMessage.length)) {
          this.notification = 'Please provide a response message'
          return false
        }
        return true
      },

      async saveResponseAction () {
        try {
          const payload = {
            senderid: this.selectedSender.senderID,
            SMSResponseActionTypeID: this.field.selectedResponseAction,
            // SMSResponseActionInfo: this.field.selectedResponseAction,
            // SMSSenderReponseActionTypeID: SMSSenderReponseActionTypeID //only when updating
          }
          if (this.field.selectedResponseAction === 1) {
            payload.SMSResponseActionInfo = this.parseCellNumberFromMaskFormat(this.field.forwardNumber)
          } else {
            payload.SMSResponseActionInfo = this.field.responseMessage
          }
          if (this.SMSSenderReponseActionTypeID) {
            payload.SMSSenderReponseActionTypeID = this.SMSSenderReponseActionTypeID
          }
          await this.httpConnector.makeRequest('post', `${this.apiEndpoint}smsreponseaction/create?code=${this.apiCode}`, payload)
        } catch (error) {
        }
      },

      async _fetchAvailableResponseActions () {
        try {
          const response = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}smsresponseactions/?code=${this.apiCode}`)
          this.senderResponseActions = response.data
        } catch (error) {
        }
      },

      async _fetchSenderResponseAction () {
        try {
          const response = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}sendersmsresponseactions/${this.selectedSender.senderID}/?code=${this.apiCode}`)
          if (response && response.data && response.data.length && response.data[0]) {
            if (response.data[0].SMSResponseActionTypeID) {
              this.field.selectedResponseAction = response.data[0].SMSResponseActionTypeID
            }
            if (response.data[0].SMSResponseActionTypeID && response.data[0].SMSResponseActionTypeID === 1) {
              this.field.forwardNumber = this.prepareMaskedNumber(response.data[0].SMSResponseActionInfo)
            }
            if (response.data[0].SMSResponseActionTypeID && response.data[0].SMSResponseActionTypeID === 2) {
              this.field.responseMessage = response.data[0].SMSResponseActionInfo
            }
            if (response.data[0].SMSSenderReponseActionTypeID) {
              this.SMSSenderReponseActionTypeID = response.data[0].SMSSenderReponseActionTypeID
            }
          }
        } catch (error) {
        }
      },

      async submit () {
        this.isFormDirty = true
        if (!this.isFormValid()) {
          this.snackbar = true
          return
        }
        if (this.isEditMode) {
          // Update
          const payload = {
            SenderID: this.selectedSender.senderID,
            SendNumber: this.parseCellNumberFromMaskFormat(this.field.number),
            SenderName: this.field.name,
            Username: this.username,
          }
          if (this.field.signature && this.field.signature.length) {
            payload.Signiture = this.field.signature
          }
          if (this.field.welcomeMessage && this.field.welcomeMessage.length) {
            payload.WelcomeMessage = this.field.welcomeMessage
          }
          if (this.field.inviteCode && this.field.inviteCode.length) {
            payload.InviteCode = this.field.inviteCode
          }
          this.$emit('on-submit')
          try {
            await this.httpConnector.makeRequest('post', `${this.apiEndpoint}messagingservice?code=${this.apiCode}`, payload)
            await this.saveResponseAction()
            this.$emit('on-success')
          } catch (error) {
            this.$emit('on-error', error)
          }
        } else {
          // Add
          const payload = {
            SendNumber: this.parseCellNumberFromMaskFormat(this.field.number),
            SenderName: this.field.name,
            Username: this.username,
          }
          if (this.field.signature && this.field.signature.length) {
            payload.Signiture = this.field.signature
          }
          if (this.field.welcomeMessage && this.field.welcomeMessage.length) {
            payload.WelcomeMessage = this.field.welcomeMessage
          }
          if (this.field.inviteCode && this.field.inviteCode.length) {
            payload.InviteCode = this.field.inviteCode
          }
          this.$emit('on-submit')
          this.httpConnector.makeRequest('post', `${this.apiEndpoint}messagingservice?code=${this.apiCode}`, payload)
            .then((res) => {
              this.$emit('on-success', res)
              this.close()
            })
            .catch((error) => {
              this.$emit('on-error', error)
            })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
.loaderWrapper {
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
